import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IFileUploadReference } from '@dominion/interfaces';
import { InputFileComponent } from '../input-file/input-file.component';
import { DiscoveryNextBtnDirective } from '../../shared/directives/discovery-next-btn.directive';

type FileInput = {
  fileInput: unknown;
  fileReference: IFileUploadReference | null;
};

@Component({
  selector: 'dominion-input-file-container',
  standalone: true,
  imports: [CommonModule, InputFileComponent, DiscoveryNextBtnDirective],
  templateUrl: './input-file-container.component.html',
  styleUrls: ['./input-file-container.component.css'],
})
export class InputFileContainerComponent implements OnInit, OnChanges {
  @Input({ required: true }) data: IFileUploadReference[];
  @Input({ required: true }) companyId: string;
  @Input({ required: true }) moduleId: string;
  @Input({ required: true }) submoduleId: string;
  @Input({ required: true }) questionKey: string;
  @Input() allowSkip: boolean = false;

  fileInputs: FileInput[] = [];

  @Output() response: EventEmitter<unknown> = new EventEmitter<unknown>();
  @Output() advanceEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.initialize();
    }
  }

  addFileInput() {
    this.fileInputs.push({ fileInput: null, fileReference: null });
  }

  removeFileInput(index: number) {
    const input = this.fileInputs[index];
    if (!input.fileInput && !input.fileReference) {
      this.fileInputs.splice(index, 1);
      return;
    }
  }

  tracker(input: FileInput) {
    if (input.fileReference) {
      return input.fileReference._id;
    } else if (input.fileInput) {
      return (input.fileInput as { file: { name: string } }).file.name;
    } else {
      return new Date().getTime();
    }
  }

  initialize() {
    this.fileInputs = [];
    if (this.data) {
      if (Array.isArray(this.data)) {
        this.fileInputs = this.data.map((fileReference) => ({
          fileInput: null,
          fileReference,
        }));
      } else {
        this.fileInputs.push({
          fileInput: null,
          fileReference: this.data,
        });
      }
    }
    if (this.fileInputs.length === 0) {
      this.fileInputs.push({ fileInput: null, fileReference: null });
    }
  }

  handleUpload(fileRef: IFileUploadReference) {
    if (Array.isArray(this.data)) {
      this.data = [...this.data, fileRef];
    } else {
      this.data = [fileRef];
    }
    this.response.emit(this.data);
  }

  handleDelete(fileId: string) {
    const filtered = this.data.filter((file) => file._id !== fileId);
    this.response.emit(filtered);
  }

  isDisabled() {
    if (this.allowSkip) {
      return false;
    } else {
      return this.fileInputs.every((input) => !input.fileReference);
    }
  }

  advance() {
    if (this.allowSkip) {
      this.advanceEvent.emit();
      return;
    }
    if (!this.isDisabled()) {
      this.advanceEvent.emit();
      return;
    }
    return;
  }
}
