import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { accountingPreliminaryStructure } from 'libs/interfaces/src/lib/modules/accounting/prelim/accounting-prelim-data.interfaces';
import { StandardGroupComponent } from '../standard-group/standard-group.component';
import { VendorGroupComponent } from '../vendor-group/vendor-group.component';
import {
  DataInitializerBasic,
  DataInitializerOther,
  IGetModuleDto,
  IGetSubmoduleFieldsBase,
  ICoreModule,
  ModuleType,
  Structures,
  SubmoduleType,
  TAllGroups,
  TSubmoduleDiscriminators,
  infraPreliminaryStructure,
  isKeyOf,
  partsPreliminaryStructure,
  servicePreliminaryStructure,
} from '@dominion/interfaces';
import { CompanyService } from '../company/company.service';
import { payrollPreliminaryStructure } from 'libs/interfaces/src/lib/modules/payroll/prelim/payroll-prelim-data.interfaces';
import { fiPreliminaryStructure } from 'libs/interfaces/src/lib/modules/fi/prelim/fi-prelim-data.interfaces';
import { salesPreliminaryStructure } from 'libs/interfaces/src/lib/modules/sales/prelim/sales-prelim-data.interfaces';
import { DiscoveryGroupDirective } from '../shared/discovery-group/discovery-group.directive';
import { DiscoveryGroupTitleComponent } from '../shared/discovery-group-title/discovery-group-title.component';
import { firstQuestionAnimation } from '../shared/animations/first-question.animation';
import { partsMaintenanceStructure } from 'libs/interfaces/src/lib/modules/parts/maintenance/parts-maintenance-data.interfaces';
import { accountingMaintenanceStructure } from 'libs/interfaces/src/lib/modules/accounting/maintenance/accounting-maintenance-data.interfaces';
import { payrollMaintenanceStructure } from 'libs/interfaces/src/lib/modules/payroll/maintenance/payroll-maintenance-data.interfaces';
import { serviceMaintenanceStructure } from 'libs/interfaces/src/lib/modules/service/maintenance/service-maintenance-data.interfaces';
import { salesMaintenanceStructure } from 'libs/interfaces/src/lib/modules/sales/maintenance/sales-maintenance-data.interfaces';

const structureMap: {
  [module in ModuleType]: {
    [submodule in SubmoduleType]: Structures[TSubmoduleDiscriminators];
  };
} = {
  accounting: {
    preliminary: accountingPreliminaryStructure,
    maintenance: accountingMaintenanceStructure,
    default: accountingPreliminaryStructure,
  },
  payroll: {
    preliminary: payrollPreliminaryStructure,
    maintenance: payrollMaintenanceStructure,
    default: payrollPreliminaryStructure,
  },
  fi: {
    preliminary: fiPreliminaryStructure,
    maintenance: fiPreliminaryStructure,
    default: fiPreliminaryStructure,
  },
  parts: {
    preliminary: partsPreliminaryStructure,
    maintenance: partsMaintenanceStructure,
    default: partsPreliminaryStructure,
  },
  service: {
    preliminary: servicePreliminaryStructure,
    maintenance: serviceMaintenanceStructure,
    default: servicePreliminaryStructure,
  },
  sales: {
    preliminary: salesPreliminaryStructure,
    maintenance: salesMaintenanceStructure,
    default: salesPreliminaryStructure,
  },
  infrastructure: {
    preliminary: infraPreliminaryStructure,
    maintenance: infraPreliminaryStructure,
    default: infraPreliminaryStructure,
  },
};

@Component({
  selector: 'dominion-group-container',
  standalone: true,
  imports: [
    CommonModule,
    StandardGroupComponent,
    VendorGroupComponent,
    DiscoveryGroupTitleComponent,
  ],
  templateUrl: './group-container.component.html',
  styleUrls: ['./group-container.component.css'],
  hostDirectives: [DiscoveryGroupDirective],
  animations: [firstQuestionAnimation],
})
export class GroupContainerComponent implements OnInit {
  _companyid: string;
  _groupUrl: string;
  _moduleid: string;
  _submoduletype: SubmoduleType;
  _submoduleid: string;
  module: ICoreModule;
  structure: Structures[TSubmoduleDiscriminators];
  group: TAllGroups;
  data: {
    [key: string]: DataInitializerBasic<any> | DataInitializerOther<any>;
  };

  @Input() set companyid(companyid: string) {
    this._companyid = companyid;
  }

  @Input() set moduleid(moduleid: string) {
    this._moduleid = moduleid;
  }

  @Input() set submoduletype(submoduletype: SubmoduleType) {
    this._submoduletype = submoduletype;
  }

  @Input() set submoduleid(submoduleid: string) {
    this._submoduleid = submoduleid;
  }

  @Input() set groupUrl(groupUrl: string) {
    this._groupUrl = groupUrl;
  }

  constructor(private companyService: CompanyService) {}

  ngOnInit(): void {
    this.getModule();
  }

  buildGetDataDto() {
    if (this._companyid && this._submoduleid && this._moduleid) {
      const fields = Object.keys(this.group.questions);
      const dto: IGetSubmoduleFieldsBase = {
        submoduleId: this._submoduleid,
        fields: fields,
      };
      return dto;
    }
    return null;
  }

  getData() {
    const dto = this.buildGetDataDto();
    if (dto) {
      this.companyService.getSubmoduleFields(dto).subscribe({
        next: (data) => {
          this.data = data;
        },
      });
    }
  }

  getModule() {
    if (this._moduleid) {
      const dto: IGetModuleDto = {
        companyId: this._companyid,
        moduleId: this._moduleid,
      };
      this.companyService.getModule(dto).subscribe({
        next: (module) => {
          this.module = module;
          this.setStructure();
        },
      });
    }
  }

  setStructure() {
    if (this.module && this._submoduletype) {
      this.structure =
        structureMap[this.module.moduleType][this._submoduletype];
      this.setGroup();
    }
  }

  setGroup() {
    if (this._groupUrl) {
      if (isKeyOf(this.structure.groups, this._groupUrl)) {
        this.group = this.structure.groups[this._groupUrl];
        this.getData();
      }
    }
  }
}
