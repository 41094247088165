import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DataInitializerBasic,
  IFileDeleteDto,
  IFileGetDto,
  IFileUploadReference,
} from '@dominion/interfaces';
import { SharedModule } from '../../shared/shared.module';
import { DiscoveryNextBtnDirective } from '../../shared/directives/discovery-next-btn.directive';
import { animate, style, transition, trigger } from '@angular/animations';
import { CompanyService } from '../../company/company.service';
import * as saveAs from 'file-saver';

@Component({
  selector: 'dominion-input-file',
  standalone: true,
  imports: [CommonModule, SharedModule, DiscoveryNextBtnDirective],
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.css'],
  animations: [
    trigger('shrinkChoose', [
      transition(':enter', [
        style({
          flexBasis: '0%',
          paddingLeft: '0px',
          paddingRight: '0px',
          marginRight: '0px',
        }),
        animate(
          '.5s ease-in-out',
          style({
            flexBasis: '33.33333%',
            paddingLeft: '8px',
            paddingRight: '8px',
            marginRight: '4px',
          }),
        ),
      ]),

      transition(':leave', [
        animate(
          '.5s ease-in-out',
          style({
            flexBasis: '0%',
            paddingLeft: '0px',
            paddingRight: '0px',
            marginRight: '0px',
          }),
        ),
        style({
          opacity: 0,
        }),
      ]),
    ]),
    trigger('shrinkControls', [
      transition(':enter', [
        style({
          width: '0%',
          'padding-left': '0px',
          'padding-right': '0px',
          'margin-left': '0px',
        }),
        animate(
          '.5s ease-in-out',
          style({
            width: '40px',
            'padding-left': '8px',
            'padding-right': '8px',
            'margin-left': '4px',
          }),
        ),
      ]),
      transition(':leave', [
        style({
          width: '40px',
          'margin-left': '*',
        }),
        animate(
          '.5s ease-in-out',
          style({
            width: '0%',
            'margin-left': '0px',
          }),
        ),
      ]),
    ]),
    trigger('slideOutRight', [
      transition(':enter', [
        style({
          transform: 'translateX(100%)',
        }),
        animate(
          '.5s ease-in-out',
          style({
            transform: 'translateX(0%)',
          }),
        ),
      ]),
      transition(':leave', [
        animate(
          '.5s ease-in-out',
          style({
            transform: 'translateX(100%)',
          }),
        ),
      ]),
    ]),
  ],
})
export class InputFileComponent implements OnInit, OnChanges {
  @Input({ required: true })
  data: DataInitializerBasic<IFileUploadReference>['value'];
  @Input({ required: true }) companyId: string;
  @Input({ required: true }) moduleId: string;
  @Input({ required: true }) submoduleId: string;
  @Input({ required: true }) questionKey: string;

  @Output() upload: EventEmitter<IFileUploadReference> =
    new EventEmitter<IFileUploadReference>();
  @Output() delete: EventEmitter<string> = new EventEmitter<string>();
  @Output() advanceEvent: EventEmitter<void> = new EventEmitter<void>();

  isDeleting: boolean = false;
  isUploading: boolean = false;
  selectedFileName: string = '';

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  constructor(private companyService: CompanyService) {}

  ngOnInit() {
    if (this.data) {
      this.selectedFileName = this.data.name;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentFile']) {
      this.selectedFileName = this.data?.name || '';
    }
  }

  fileInputChange(event: unknown) {
    event;
    const files = this.fileInput.nativeElement.files;
    if (files) {
      this.selectedFileName = files[0].name;
    }
    this.uploadFile();
  }

  isUploadValid() {
    if (
      this.fileInput &&
      this.fileInput.nativeElement.files &&
      this.fileInput.nativeElement.files.length > 0
    ) {
      return true;
    }
    return false;
  }

  resetFileInput() {
    this.selectedFileName = '';
    this.fileInput.nativeElement.value = '';
    this.fileInput.nativeElement.files = null;
  }

  uploadFile() {
    if (
      this.fileInput.nativeElement.files &&
      this.fileInput.nativeElement.files.length > 0
    ) {
      this.isUploading = true;
      const file = this.fileInput.nativeElement.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', file.name);
      formData.append('companyId', this.companyId);
      formData.append('moduleId', this.moduleId);
      formData.append('submoduleId', this.submoduleId);
      formData.append('field', this.questionKey);
      this.companyService.uploadFile(formData).subscribe({
        next: (res) => {
          this.isUploading = false;
          this.data = res;
          this.upload.emit(res);
        },
      });
    }
  }

  getFile() {
    if (this.data) {
      const dto: IFileGetDto = {
        companyId: this.data.companyId,
        fileId: this.data._id,
      };
      this.companyService.getFile(dto).subscribe({
        next: (res) => {
          const asBlob = new Blob([res]);
          saveAs(asBlob, this.data?.name || 'file');
        },
      });
    }
  }

  deleteFile() {
    if (this.data) {
      this.isDeleting = true;
      const dto: IFileDeleteDto = {
        companyId: this.data.companyId,
        submoduleId: this.submoduleId,
        fileId: this.data._id,
        field: this.questionKey,
      };
      this.companyService.deleteFile(dto).subscribe({
        next: (res) => {
          res;
          this.isDeleting = false;
          this.data = null;
          this.selectedFileName = '';
          this.delete.emit(dto.fileId);
        },
      });
    }
  }
}
