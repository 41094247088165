import { Component, Directive, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from '../company.service';
import {
  AuthorizationStrategy,
  CompanyAuthRole,
  ICompanyUpdateDto,
  DominionAuthRole,
  ICompanyReadFull,
  ICompanyUpdateEvent,
  IDMS,
  IDMSList,
  IDMSListLabels,
  IDMSOptions,
  IRequestUserPayload,
  IUserAuthorizations,
  IInvitedUserCreate,
  StateOptions,
  ICoreModuleCreate,
  ICoreModuleUpdate,
  ISubmoduleUpdate,
  ICoreModuleAddUserDto,
} from '@dominion/interfaces';
import { EditableTextComponent } from '../../shared/editable-text/editable-text.component';
import { EditableSelectComponent } from '../../shared/editable-select/editable-select.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthorizationService } from '../../auth/authorization.service';
import { AuthenticationService } from '../../auth/authentication.service';
import { Types } from 'mongoose';
import {
  CompanyAdminStrategy,
  CompanySuperStrategy,
  DominionAnyStrategy,
  DominionProjectManagerStrategy,
} from '@dominion/authorization';
import { CompanyUsersComponent } from '../company-users/company-users.component';
import { UserService } from '../../user/user.service';
import {
  CompanyProjectComponent,
  UpdateEmission,
} from '../company-project/company-project.component';
import { SharedModule } from '../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { CompanyRelatedCompaniesComponent } from '../company-related-companies/company-related-companies.component';
import { CompanyTeamComponent } from '../company-team/company-team.component';
import { ExpandableDirective } from '../../shared/directives/expandable.directive';
import { ExpandableHostDirective } from '../../shared/directives/expandable-host.directive';
import { ExpandableToggleDirective } from '../../shared/directives/expandable-toggle.directive';
import { InformationButtonComponent } from '../../shared/information-button/information-button.component';
import { parseISO, startOfDay } from 'date-fns';

@Directive({
  selector: '[dominionInfoLabel]',
  standalone: true,
  host: {
    class: 'text-sm text-gray-500',
  },
})
export class InfoLabelDirective {}

@Directive({
  selector: '[dominionValue]',
  standalone: true,
  host: {
    class: 'text-lg font-bold',
  },
})
export class ValueDirective {}

@Component({
  selector: 'dominion-company-detail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.css'],
  imports: [
    CompanyProjectComponent,
    SharedModule,
    CommonModule,
    InfoLabelDirective,
    CompanyUsersComponent,
    CompanyRelatedCompaniesComponent,
    CompanyTeamComponent,
    ExpandableDirective,
    InformationButtonComponent,
    ExpandableHostDirective,
    ExpandableToggleDirective,
    ValueDirective,
  ],
  standalone: true,
  host: {
    class: 'block w-full h-full container mx-auto overflow-y-scroll pb-12',
  },
})
export class CompanyDetailComponent implements OnInit {
  public internalView: boolean = false;
  public user: IRequestUserPayload | undefined;

  public userDominionRole: DominionAuthRole | undefined;
  public userCompanyRole: CompanyAuthRole | undefined;

  public company: ICompanyReadFull | undefined;
  public IDMSOptions = IDMSOptions;
  public IDMSListLabels = IDMSListLabels;
  public stateOptions = StateOptions;
  public cedPattern =
    /^(0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])[\/\-]\d{4}$/;
  public isLoading = false;
  public isSendingInvite = false;

  public inviteForm: FormGroup;
  public inviteOpen = false;
  public genInviteErr: HttpErrorResponse | undefined;

  // PERMISSIONS
  public canEditCompanyDetails: boolean = false;
  public canInviteSuper: boolean = false;
  public canInviteAdmin: boolean = false;
  public canInviteManager: boolean = false;
  public canAddModule: boolean = false;
  public canEditModuleDetails: boolean = false;
  public canSeeInternalDetails: boolean = false;
  public canAssignModuleUser: boolean = false;

  @ViewChild(CompanyUsersComponent) usersComponent!: CompanyUsersComponent;
  @ViewChild(CompanyProjectComponent)
  projectComponent!: CompanyProjectComponent;

  constructor(
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private fb: FormBuilder,
    private authorization: AuthorizationService,
    private authentication: AuthenticationService,
    private userService: UserService,
    private router: Router,
  ) {
    this.inviteForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      role: ['', Validators.required],
    });
  }

  inviteUser(dto: IInvitedUserCreate) {
    this.userService.addUserToCompany(dto).subscribe({
      next: (res) => {
        this.usersComponent.inviteSucceeded();
        const authorizations: IUserAuthorizations = {};
        for (const companyRole of dto.companyRoles) {
          authorizations[`company:${companyRole.companyId}`] = {
            roles: [companyRole.role],
            permissions: [],
          };
        }
        if (res && res.inviteId) {
          this.company!.invitedUsers.push({
            _id: res.inviteId as any,
            email: dto.email,
            invite: {
              invitedOn: new Date(),
              invitedBy: '',
            },
            authorizations: authorizations,
          });
        }
      },
      error: (err: HttpErrorResponse) => {
        this.usersComponent.setGenErr(err);
      },
    });
  }

  getCompany(_id: string) {
    this.isLoading = true;
    this.companyService.getCompany(_id).subscribe({
      next: (company) => {
        this.company = company;
        this.isLoading = false;
        this.initializePermissions();
        this.setExternalAssigned();
      },
      error: (err) => console.error(err),
    });
  }

  setExternalAssigned() {
    if (
      !this.company ||
      !this.company.modules ||
      this.company.modules.length === 0
    ) {
      return;
    }
    for (const module of this.company.modules) {
      if (module.externalAssigned && module.externalAssigned.length > 0) {
        // find and set these users
        const newArray = [];
        for (let user of module.externalAssigned) {
          let userObj = this.company.users.find(
            (u) =>
              u._id === (typeof user === 'object' ? (user as any)._id : user),
          ) as
            | ICompanyReadFull['users'][0]
            | ICompanyReadFull['invitedUsers'][0]
            | undefined;
          if (!userObj) {
            userObj = this.company.invitedUsers.find(
              (u) =>
                u._id === (typeof user === 'object' ? (user as any)._id : user),
            );
          }
          if (userObj) {
            newArray.push(userObj);
          }
        }
        module.externalAssigned = newArray as any;
      }
    }
  }

  getDMSLabel(dms: string) {
    if (IDMSList.includes(dms as IDMS)) {
      return IDMSListLabels[dms as IDMS];
    }
    return dms;
  }

  onCompanyUpdate(
    event: ICompanyUpdateEvent,
    editableComp: EditableTextComponent | EditableSelectComponent,
  ) {
    const addressKeys = ['street', 'city', 'line2', 'state', 'zip'];
    if (addressKeys.includes(event.field)) {
      const address = this.company!.address;
      (address as any)[event.field] = event.value;
      const dto: ICompanyUpdateDto = {
        address: address,
      };
      this.updateCompany(dto, editableComp);
      return;
    }
    if (
      event.field === 'contractExpirationDate' ||
      event.field === 'preferredInstallDate' ||
      event.field === 'goLiveDate'
    ) {
      const dto: ICompanyUpdateDto = {
        [event.field]: startOfDay(parseISO(event.value as string)),
      };
      this.updateCompany(dto, editableComp);
      return;
    }
    const dto: ICompanyUpdateDto = {
      [event.field]: event.value,
    };
    this.updateCompany(dto, editableComp);
  }

  updateCompany(
    dto: ICompanyUpdateDto,
    editableComp: EditableTextComponent | EditableSelectComponent,
  ) {
    this.companyService
      .updateCompany(this.company!._id as string, dto)
      .subscribe({
        next: () => {
          this.company = Object.assign(this.company!, dto);
          editableComp.saveSucceeded();
        },
        error: (err) => {
          console.error(err);
          editableComp.saveFailed(err.error.message);
        },
      });
  }

  goToCompany(id: string | Types.ObjectId) {
    if (this.internalView) {
      this.router.navigateByUrl(`core/internal/companies/detail/${id}`);
    } else {
      this.router.navigateByUrl(`core/external/${id}`);
    }
  }

  initializePermissions() {
    if (!this.company) {
      return;
    }
    if (!this.authorization.authorizations) {
      return;
    }
    const editDetailsStrategies: AuthorizationStrategy = [
      new DominionProjectManagerStrategy(),
    ];
    this.canEditCompanyDetails = this.authorization.isAuthorized(
      editDetailsStrategies,
    );
    const canInviteSuperStrategies: AuthorizationStrategy = [
      new DominionProjectManagerStrategy(),
    ];
    this.canInviteSuper = this.authorization.isAuthorized(
      canInviteSuperStrategies,
    );
    const canInviteAdminStrategies: AuthorizationStrategy = [
      new DominionProjectManagerStrategy(),
      new CompanySuperStrategy(this.company._id as string),
    ];
    this.canInviteAdmin = this.authorization.isAuthorized(
      canInviteAdminStrategies,
    );
    const canInviteManagerStrategies: AuthorizationStrategy = [
      new DominionProjectManagerStrategy(),
      new CompanyAdminStrategy(this.company._id as string),
    ];
    this.canInviteManager = this.authorization.isAuthorized(
      canInviteManagerStrategies,
    );
    this.canAddModule = this.authorization.isAuthorized([
      new DominionProjectManagerStrategy(),
    ]);
    this.canEditModuleDetails = this.authorization.isAuthorized([
      new DominionProjectManagerStrategy(),
    ]);
    this.canSeeInternalDetails = this.authorization.isAuthorized([
      new DominionAnyStrategy(),
    ]);
    this.canAssignModuleUser = this.authorization.isAuthorized([
      new DominionProjectManagerStrategy(),
      new CompanyAdminStrategy(this.company._id),
    ]);
  }

  subscribeToAuth() {
    this.authentication.authorizations.subscribe({
      next: () => {
        this.initializePermissions();
      },
    });
  }

  /*
    COMPANY PROJECT AND MODULES
  */

  // ADD MODULE

  addModuleToCompany(dto: ICoreModuleCreate) {
    this.companyService.addModuleToCompany(dto).subscribe({
      next: (res) => {
        if (res) {
          this.company!.modules.push(res as any);
          this.projectComponent.addModuleSucceeded();
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  updateModuleDetails(update: UpdateEmission<ICoreModuleUpdate>) {
    this.companyService.updateModuleDetails(update.dto).subscribe({
      next: () => {
        let module = this.company!.modules.find(
          (m) => (m._id as unknown as string) === update.dto.moduleId,
        );
        if (module) {
          const { companyId, moduleId, ...rest } = update.dto;
          module = Object.assign(module, rest);
        }
        update.editable.saveSucceeded();
      },
      error: (err) => {
        console.error(err);
        update.editable.saveFailed(err.error.message);
      },
    });
  }

  updateSubmoduleDetails(update: UpdateEmission<ISubmoduleUpdate>) {
    this.companyService.updateSubmoduleDetails(update.dto).subscribe({
      next: () => {
        let module = this.company!.modules.find(
          (m) => m._id === update.dto.moduleId,
        );
        if (module) {
          const { companyId, moduleId, submoduleId, ...rest } = update.dto;
          if (
            module.submodules.preliminary.submoduleId === update.dto.submoduleId
          ) {
            module.submodules.preliminary = Object.assign(
              module.submodules.preliminary,
              rest,
            );
          } else if (
            module.submodules.maintenance.submoduleId === update.dto.submoduleId
          ) {
            module.submodules.maintenance = Object.assign(
              module.submodules.maintenance,
              rest,
            );
          } else if (
            module.submodules.default.submoduleId === update.dto.submoduleId
          ) {
            module.submodules.default = Object.assign(
              module.submodules.default,
              rest,
            );
          }
        }
        update.editable.saveSucceeded();
      },
      error: (err) => {
        console.error(err);
        update.editable.saveFailed(err.error.message);
      },
    });
  }

  assignUserToModule(dto: ICoreModuleAddUserDto) {
    this.companyService.assignUserToModule(dto).subscribe({
      next: () => {
        let module = this.company!.modules.find(
          (m) => (m._id as unknown as string) === dto.moduleId,
        );
        if (module) {
          module.externalAssigned[0] = dto.userId;
          this.setExternalAssigned();
        }
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params['companyid']) {
        this.getCompany(params['companyid']);
      }
    });
    this.authentication.user.subscribe({
      next: (user) => {
        this.user = user;
      },
    });

    this.route.parent?.url.subscribe((url) => {
      url[0].path === 'admin'
        ? (this.internalView = true)
        : (this.internalView = false);
    });
    this.subscribeToAuth();
  }
}
